import React, { useState } from "react";

const MobileHistory = () => {
  const [tab, setTab] = useState("tab-1");
  const onChangeTab = (tab) => {
    setTab(tab);
  };
  return (
    <div className="m_company_wrap">
      <div className="company_tit">
        <h3>회사연혁</h3>
        <p>Business History</p>
      </div>
      <div className="company_cot">
        <div className="outer">
          <div
            className="m_tab_buttons bhistory"
            style={{ display: "block", overflow: "hidden", width: "100%" }}
          >
            <button
              className={`${tab === "tab-1" ? "active" : ""} dptab`}
              onClick={() => onChangeTab("tab-1")}
            >
              2020
              <br />
              -현재
            </button>
            <button
              className={`${tab === "tab-2" ? "active" : ""} dptab`}
              onClick={() => onChangeTab("tab-2")}
            >
              2015
              <br />
              -2019
            </button>
            <button
              className={`${tab === "tab-3" ? "active" : ""} dptab`}
              onClick={() => onChangeTab("tab-3")}
            >
              2010
              <br />
              -2014
            </button>
            <button
              className={`${tab === "tab-4" ? "active" : ""} dptab`}
              onClick={() => onChangeTab("tab-4")}
            >
              2005
              <br />
              -2009
            </button>
          </div>
          <div className="m_tabs">
            <div className={`tab ${tab === "tab-1" ? "active" : ""}`}>
              <ul className="m_prd_box2">
                <div className="slick-list m_history_cont">
                  <dl className="m_history_right">
                          <dt>2020.03</dt>
                          <dd>플랫폼 APP Biz 호가거래 "센골드" 출시</dd>
                        </dl>
                        <dl className="m_history_left">
                          <dt>2020.05</dt>
                          <dd>실버 유가증권 판매시작(1,000g)</dd>
                        </dl>
                        <dl className="m_history_right">
                          <dt>2020.07</dt>
                          <dd>
                            <span className="ldot"></span>골드 유가증권
                            판매시작(10g)
                          </dd>
                          <dd>
                            <span className="ldot"></span>KRX금시장 위원회 위원
                            위촉(김안모 대표이사)
                          </dd>
                        </dl>
                        <dl className="m_history_left">
                          <dt>2020.10</dt>
                          <dd>대한적십자사 포장증</dd>
                        </dl>
                        <dl className="m_history_right">
                          <dt>2020.12</dt>
                          <dd>매출 1조 8,200억 달성</dd>
                        </dl>
                        <dl className="m_history_left">
                          <dt>2021.01</dt>
                          <dd>코리아테크노브레인(KTB)자회사 편입 인수</dd>
                        </dl>
                        <dl className="m_history_right">
                          <dt>2021.03</dt>
                          <dd>한국조폐공사 근속기념메달 외주가공 연간계약</dd>
                        </dl>
                        <dl className="m_history_left">
                          <dt>2021.07</dt>
                          <dd>KRX금시장 적격금지금생산업자 승인등록(KTB)</dd>
                        </dl>
                        <dl className="m_history_right">
                          <dt>2021.10</dt>
                          <dd>자회사 ㈜한국금거래소FTC 사명변경 (舊 KTB)</dd>
                        </dl>
                        <dl className="m_history_left">
                          <dt>2021.11</dt>
                          <dd>CEO Community 경제전문지 대표이사 성공사례</dd>
                        </dl>
                        <dl className="m_history_right">
                          <dt>2021.12</dt>
                          <dd>
                            플랫폼 APP Biz "금방금방" 개인간 중개거래 개시
                          </dd>
                        </dl>
                        <dl className="m_history_left">
                          <dt>2021.12</dt>
                          <dd>매출 2조 6,300억 달성(KorDA 3,000억 초과달성)</dd>
                        </dl>
                        <dl className="m_history_right">
                          <dt>2022.01</dt>
                          <dd>㈜KVM Indium 임가공 계약체결 (舊 삼성코닝)</dd>
                        </dl>
                        <dl className="m_history_left">
                          <dt>2022.12</dt>
                          <dd>
                            플랫폼 APP Biz"금방금방" 개인間 중개거래 개시
                          </dd>
                          <dd>
                            매출 2조6,300억 달성 (KorDA 3,000억 초과달성)
                          </dd>
                        </dl>
                        <dl className="m_history_right">
                          <dt>2023.07</dt>
                          <dd>한국금거래소 "금 자판기" 이마트 용산점 최초 설치</dd>
                        </dl>
                        <dl className="m_history_left">
                          <dt>2023.10</dt>
                          <dd>한국금거래소FTC 귀금속 전용 정련 공장 완공</dd>
                        </dl>
                        <dl className="m_history_right">
                          <dt>2023.11</dt>
                          <dd>한국금거래소 "금 자판기" 롯데아울렛 파주점 최초 설치</dd>
                        </dl>
                        <dl className="m_history_left">
                          <dt>2024.02</dt>
                          <dd>
                            한국금거래소FTC UL2809 인증
                          </dd>
                          <dd>
                            그린 마케팅 선언(탄소중립)
                          </dd>
                          <dd>
                            재생금 공급 시작
                          </dd>
                        </dl>
                        <dl className="m_history_right">
                          <dt>2024.03</dt>
                          <dd>KRX 금시장 공로상 수상</dd>
                        </dl>
                        <dl className="m_history_left">
                          <dt>2024.04</dt>
                          <dd>탄소중립(ESG경영) 우수사례 입상</dd>
                        </dl>
                        <dl className="m_history_right">
                          <dt>2024.12</dt>
                          <dd>
                            연매출 3조 8,200억원 달성
                          </dd>
                          <dd>
                            KRX 금시장 누적 거래액 2조 1,533억원
                          </dd>
                          <dd>
                            수출 2조 5,707억원 달성(2015~2024)
                          </dd>
                          <dd>
                            전국 한국금거래소 가맹점 101개점 개설
                          </dd>
                        </dl>
                        <dl className="m_history_left">
                          <dt>2025.03</dt>
                          <dd>싱가포르 금시장 협회(SBMA)의 회원 가입 인증</dd>
                        </dl>
                </div>
              </ul>
            </div>
            <div className={`tab ${tab === "tab-2" ? "active" : ""}`}>
              <ul className="m_prd_box2">
                <div className="slick-list m_history_cont">
                  <dl className="m_history_right">
                    <dt>2015.01</dt>
                    <dd>
                      <span className="ldot"></span>삼성증권 골드바 판매 계약
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2015.02</dt>
                    <dd>
                      <span className="ldot"></span>제주은행, 이트레이드 증권
                      골드바 판매 계약
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2015.03</dt>
                    <dd>
                      <span className="ldot"></span>IBK기업은행 골드바 판매 계약
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2015.04</dt>
                    <dd>
                      <span className="ldot"></span>부산은행, 대구은행,
                      HK저축은행 골드바 판매 계약
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2015.05</dt>
                    <dd>
                      <span className="ldot"></span>신세계몰 우수협력사 표창
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2015.07</dt>
                    <dd>
                      <span className="ldot"></span>삼성저축은행 골드바 판매
                      계약
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2015.09</dt>
                    <dd>
                      <span className="ldot"></span>ISO9001 인증 획득,
                      BNK저축은행 골드바 판매 계약
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2015.10</dt>
                    <dd>
                      <span className="ldot"></span>대신저축은행, 스마트저축은행
                      골드바 판매 계약
                    </dd>
                    <dd>
                      <span className="ldot"></span>인도 수출 계약
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2015.11</dt>
                    <dd>
                      <span className="ldot"></span>친애저축은행 골드바 판매
                      계약
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2015.12</dt>
                    <dd>
                      <span className="ldot"></span>NH농협은행 골드바 판매 계약
                    </dd>
                    <dd>
                      <span className="ldot"></span>연매출 1조 120억 달성,
                      누적매출 4조 9870억 돌파
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2016.01</dt>
                    <dd>
                      <span className="ldot"></span>한국금거래소
                      청담본점(엠브로) 개관
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2016.12</dt>
                    <dd>
                      <span className="ldot"></span>제1회 보석의날
                      서울특별시장상 수상
                    </dd>
                    <dd>
                      <span className="ldot"></span>53회 무역의날 기념 5천만불
                      수출탑, 산업포장 수상
                    </dd>
                    <dd>
                      <span className="ldot"></span>산업통상자원부 장관표창 수상
                    </dd>
                    <dd>
                      <span className="ldot"></span>신한은행장 외환거래 감사패
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2017.10</dt>
                    <dd>
                      <span className="ldot"></span>신세계백화점 골드바 판매
                      계약 체결
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2017.12</dt>
                    <dd>
                      <span className="ldot"></span>신한은행 골드바 판매 계약
                    </dd>
                    <dd>
                      <span className="ldot"></span>2017년 주얼리 함쟝 조사 품질
                      우수업체 수상
                    </dd>
                    <dd>
                      <span className="ldot"></span>54회 무역의날 기념 7천불
                      수출탑 수상
                    </dd>
                    <dd>
                      <span className="ldot"></span>연매출 1조 5천억원 달성
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2018.02</dt>
                    <dd>
                      <span className="ldot"></span>현대홈쇼핑 순금 주얼리 판매
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2018.03</dt>
                    <dd>
                      <span className="ldot"></span>GIA 한국 총동문회 세미나 및
                      감사패 수상
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2018.04</dt>
                    <dd>
                      <span className="ldot"></span>동서울대학교 협약 체결
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2018.05</dt>
                    <dd>
                      <span className="ldot"></span>조폐공사 외주가공 연간 계약
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2018.12</dt>
                    <dd>
                      <span className="ldot"></span>55회 무역의 날 기념 5억불
                      수출탑 수상
                    </dd>
                    <dd>
                      <span className="ldot"></span>E-commerce 온라인몰 28개사
                      확대 공급
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2019.01</dt>
                    <dd>
                      <span className="ldot"></span>이마트 골드바 판매 계약 체결
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2019.02</dt>
                    <dd>
                      <span className="ldot"></span>엠브로 다이아몬드클럽 론칭
                    </dd>
                  </dl>
                </div>
              </ul>
            </div>
            <div className={`tab ${tab === "tab-3" ? "active" : ""}`}>
              <ul className="m_prd_box2">
                <div className="slick-list m_history_cont">
                  <dl className="m_history_right">
                    <dt>2010.12</dt>
                    <dd>
                      <span className="ldot"></span>실시간 국제,국내 금시세 정보
                      제공
                    </dd>
                    <dd>
                      <span className="ldot"></span>순금나라 대리점 55개점 개설
                    </dd>
                    <dd>
                      <span className="ldot"></span>누적매출 1조2천억원
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2011.03</dt>
                    <dd>
                      <span className="ldot"></span>순금나라 대리점 70개점 개설
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2011.12</dt>
                    <dd>
                      <span className="ldot"></span>KRX) 주관 환리스크 관리
                      최우수 기업상 수상
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2012.12</dt>
                    <dd>
                      <span className="ldot"></span>한국조폐공사 Gold/Silver Bar
                      제조 공급 계약
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2013.01</dt>
                    <dd>
                      <span className="ldot"></span>한국금거래소쓰리엠으로
                      상호변경, <br />
                      한국금거래소 빌딩으로 사옥이전
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2013.03</dt>
                    <dd>
                      <span className="ldot"></span>한국조폐공사, 국민은행
                      업무제휴
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2013.05</dt>
                    <dd>
                      <span className="ldot"></span>서울머니쇼 참가
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2013.09</dt>
                    <dd>
                      <span className="ldot"></span>우리은행/경남은행 골드바
                      판매계약
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2014.01</dt>
                    <dd>
                      <span className="ldot"></span>인천아시안게임 공식후원사
                      지정,
                      <br /> 공식 골드바/실버바 공급
                    </dd>
                    <dd>
                      <span className="ldot"></span>연매출 1조 120억 달성,
                      누적매출 4조 9870억 돌파
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2014.02</dt>
                    <dd>
                      <span className="ldot"></span>신한은행 업무제휴
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2014.04</dt>
                    <dd>
                      <span className="ldot"></span>제2회 대한민국 귀금속
                      함량기술콘테스트
                      <br /> 원자재부문 우수상 수상
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2014.06</dt>
                    <dd>
                      <span className="ldot"></span>우리은행 실버바 판매 계약
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2014.10</dt>
                    <dd>
                      <span className="ldot"></span>하나은행 골드바 판매 계약
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2014.12</dt>
                    <dd>
                      <span className="ldot"></span>한국조폐공사 우수협력사 표창
                    </dd>
                  </dl>
                </div>
              </ul>
            </div>
            <div className={`tab ${tab === "tab-4" ? "active" : ""}`}>
              <ul className="m_prd_box2">
                <div className="slick-list m_history_cont">
                  <dl className="m_history_right">
                    <dt>1995.01</dt>
                    <dd>
                      <span className="ldot"></span>비치나귀금속 창업
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2005.12</dt>
                    <dd>
                      <span className="ldot"></span>㈜한국귀금속쓰리엠 창립
                      연매출 1000억 달성
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2006.12</dt>
                    <dd>
                      <span className="ldot"></span>연매출 2000억 달성
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2007.12</dt>
                    <dd>
                      <span className="ldot"></span>연매출 2500억 달성
                    </dd>
                  </dl>
                  <dl className="m_history_right">
                    <dt>2008.12</dt>
                    <dd>
                      <span className="ldot"></span>금매입전문브랜드 순금나라
                      런칭 연매출 3400억 달성
                    </dd>
                  </dl>
                  <dl className="m_history_left">
                    <dt>2009.12</dt>
                    <dd>
                      <span className="ldot"></span>연매출 3400억 달성
                    </dd>
                  </dl>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHistory;
